<template>
  <ContactsComponent />
</template>

<script>

const ContactsComponent = () => import('../components/main/ContactsComponent')

export default {
  name: 'Contacts',
  components: { ContactsComponent }
}
</script>

<style scoped>

</style>
